import React, { useCallback } from "react"
import { AnimatedLink } from "@components/shared"
import { INavItem, IMenu, IClickableDiv } from "./types"

import {
  menu__open,
  menu__closed,
  menu__nav,
  menu__nav__medias,
  menu__nav__list__item,
} from "./header.module.scss"
import Icon1 from "../../standard/Home/icons/youtube1.svg"
import Icon2 from "../../standard/Home/icons/i.svg"
import Icon3 from "../../standard/Home/icons/fb.svg"
const navLinks: Array<INavItem> = [
  {
    name: "Sklep",
    url: "/",
  },
  {
    name: "Autorzy",
    url: "/",
  },
  {
    name: "Blog",
    url: "/blog",
  },
  {
    name: "Kontakt",
    url: "/kontakt",
  },
]

// uniwersalny
const ClickableDiv: React.FC<IClickableDiv> = (props) => <div {...props} />

const Menu: React.FC<IMenu> = ({ isMenuOpen, toggleMenu }) => {
  const handleLinkClick = useCallback(
    (e) => {
      if (e.target.hasAttribute("href")) toggleMenu()
    },
    [isMenuOpen]
  )

  return (
    <ClickableDiv
      className={isMenuOpen ? menu__open : menu__closed}
      onClick={handleLinkClick}
    >
      <nav className={menu__nav}>
        <ul>
          {navLinks.map(({ name, url, button }) => {
            return (
              <li key={name}>
                <AnimatedLink to={url} className={menu__nav__list__item}>
                  {name}
                </AnimatedLink>
              </li>
            )
          })}
        </ul>
        <div className={menu__nav__medias}>
          <AnimatedLink to="/">
            <img src={Icon2}></img>
          </AnimatedLink>
          <AnimatedLink to="/">
            <img src={Icon3}></img>
          </AnimatedLink>
          <AnimatedLink to="/">
            <img src={Icon1}></img>
          </AnimatedLink>
        </div>
      </nav>
    </ClickableDiv>
  )
}

export default Menu

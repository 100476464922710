import React, { useState, useEffect, useCallback, useContext } from "react"
import { Helmet } from "react-helmet"
import { WindowCtx } from "@components/contexted"
import { INavItem } from "./types"
import Menu from "./Menu"
import { AnimatedLink } from "@components/shared"
import Hamburger from "./Hamburger"
import Icon1 from "../../standard/Home/icons/youtube1.svg"
import Icon2 from "../../standard/Home/icons/i.svg"
import Icon3 from "../../standard/Home/icons/fb.svg"
import Icon4 from "../../standard/Home/icons/User.svg"
import Leaf from "../../standard/Home/icons/leaf21.png"
import {
  header,
  logo,
  nav,
  nav__container,
  nav__container__medias,
  nav__container__medias__left,
  nav__container__links,
  nav__container__link,
  nav__container__button,
  nav__container__links__left,
} from "./header.module.scss"

const navLinks: Array<INavItem> = [
  {
    name: "Nasze oleje",
    url: "/",
  },
  {
    name: "Kontakt",
    url: "/kontakt",
  },
  {
    name: "Blog",
    url: "/blog",
  },
]

const Header: React.FC = (): JSX.Element => {
  const { y } = useContext(WindowCtx)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const handleKeydown = useCallback((e) => {
    if (!isMenuOpen) return
    if (e.which === 27 || e.key === "Escape") toggleMenu()
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", (e: Event) => handleKeydown(e))
    return () =>
      window.removeEventListener("keydown", (e: Event) => handleKeydown(e))
  }, [])

  return (
    <header className={header}>
      <Helmet>
        <body className={isMenuOpen ? "blocked" : ""} />
      </Helmet>

      <nav className={nav}>
        <div className={logo}>
          <AnimatedLink to="/">
            <img src={Leaf}></img>
          </AnimatedLink>
        </div>
        <div className={nav__container}>
          <div className={nav__container__links}>
            <ul className={nav__container__links__left}>
              {navLinks.map((link) => (
                <li>
                  <AnimatedLink to={link.url} className={nav__container__link}>
                    {link.name}
                  </AnimatedLink>
                </li>
              ))}
            </ul>
            <AnimatedLink to="/" className={nav__container__button}>
              Sprawdź badania
            </AnimatedLink>
          </div>
          <div className={nav__container__medias}>
            <div className={nav__container__medias__left}>
              <AnimatedLink to="/">
                <img src={Icon2}></img>
              </AnimatedLink>
              <AnimatedLink to="/">
                <img src={Icon3}></img>
              </AnimatedLink>
              <AnimatedLink to="/">
                <img src={Icon1}></img>
              </AnimatedLink>
            </div>
            <AnimatedLink to="/">
              <img src={Icon4}></img>
            </AnimatedLink>
          </div>
        </div>

        <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      </nav>

      <Menu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </header>
  )
}

export default Header

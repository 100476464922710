import React, { useMemo } from "react"
import { useSpring, animated } from "react-spring/"
import { useIntersectionObserver } from "@components/logic"

import { IAnimatedSection } from "./types"

const randomizer = (border = 40) => -1 * border + 2 * Math.random() * border

const random = () => (Math.random() > 0.5 ? 300 : -300)

const Section: React.FC<IAnimatedSection> = ({ children, className }) => {
  const [{ inView }, marktoObserve] = useIntersectionObserver()
  const randomNumber = useMemo(() => random(), [])
  const { opacity, marginRight } = useSpring({
    from: {
      opacity: 0,
      marginRight: randomNumber,
    },
    to: {
      opacity: inView ? 1 : 0,
      marginRight: inView ? 0 : randomNumber,
    },
  })

  return (
    <animated.div
      ref={marktoObserve}
      className={className}
      style={{ opacity, marginRight }}
    >
      {children}
    </animated.div>
  )
}

export default Section

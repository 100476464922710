import React from "react"
import { useLocation } from "@reach/router"
import {
  footer,
  footer__links,
  footer__links__list,
  footer__contact,
  footer__contact__list,
  footer__image,
  footer__container,
} from "./footer.module.scss"
import Logo from "../../standard/Home/icons/theoldhempLOGOtext.svg"
import Logo1 from "../../standard/Home/icons/Path38.svg"
import Logo2 from "../../standard/Home/icons/Path39.svg"
import Logo3 from "../../standard/Home/icons/Path40.svg"
import { AnimatedLink } from "@components/shared"
const Footer: React.FC = (): JSX.Element => {
  const { pathname } = useLocation()

  return (
    <footer className={footer}>
      <div className={footer__container}>
        <img src={Logo} alt="footer-logo" className={footer__image} />
        <div className={footer__links}>
          <div className={footer__links__list}>
            <AnimatedLink>About us</AnimatedLink>
            <AnimatedLink>Contact</AnimatedLink>
            <AnimatedLink>Terms & Conditions</AnimatedLink>
          </div>
          <div className={footer__links__list}>
            <AnimatedLink>
              <img src={Logo1} style={{ marginRight: "18px" }} />
              Facebook
            </AnimatedLink>
            <AnimatedLink>
              <img src={Logo2} />
              Twitter
            </AnimatedLink>
            <AnimatedLink>
              <img src={Logo3} />
              Instagram
            </AnimatedLink>
          </div>
        </div>
      </div>
      <div className={footer__contact}>
        <ul className={footer__contact__list}>
          <li>497 Evergreen Rd. Roseville, CA 95673</li>
          <li>+44 345 678 903</li>
          <li>adobexd@mail.com</li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
